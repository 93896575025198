import React, { useEffect, useState } from 'react';
import { sha256 } from 'js-sha256';
import LoadingSpinner from './utils/Loading';


const config = {
  authorizationUrl: process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  scopes: 'openid profile',
};

const AuthComponent: React.FC = () => {
 // const [code, setCode] = useState<string | null>(null);
  const generateRandomCodeVerifier = (): string => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    const verifierLength = 128;
    let codeVerifier = '';
    for (let i = 0; i < verifierLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      codeVerifier += charset.charAt(randomIndex);
    }
    return codeVerifier;
  };


  const generateCodeChallenge = (codeVerifier: string): string => {
    // Generate SHA-256 hash of the code verifier
    const hash = sha256.create();
    hash.update(codeVerifier);
    const codeChallenge = hash.arrayBuffer();

    // Convert ArrayBuffer to base64UrlEncoded string
    return base64UrlEncode(codeChallenge);
  };

  const base64UrlEncode = (buffer: ArrayBuffer): string => {
    const array = new Uint8Array(buffer);
    return btoa(String.fromCharCode(...array))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  };


  const storeCodeVerifierInLocalStorage = (codeVerifier: string) => {
    localStorage.setItem("CodeVerifier", codeVerifier)
  }

  const getCodeVerifierFromLocalStorage = (): string => {
    var test = localStorage.getItem("CodeVerifier");
    return test;
  }

  const redirectToAuthorizationUrl = async () => {
    const codeVerifier = generateRandomCodeVerifier();
    const codeChallenge = generateCodeChallenge(codeVerifier);

    // Store the code verifier for later use
    storeCodeVerifierInLocalStorage(codeVerifier);

    const authorizationUrl = `${config.authorizationUrl}?client_id=${config.clientId}&redirect_uri=${config.redirectUri}&scope=${config.scopes}&response_type=code&code_challenge=${codeChallenge}&code_challenge_method=S256`;

    window.location.href = authorizationUrl;
    console.log('Authenticated successfully!');
  };

  useEffect(() => {
    const initiateAuthFlow = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      // If code is not present, initiate the authentication flow
      if (!code) {
        await redirectToAuthorizationUrl();
      }
    };

    initiateAuthFlow();
  }, []);

  return (
    <LoadingSpinner/>
  );
};

export default AuthComponent;
