import React from 'react';


const LoadingSpinner = () => {
  return (
    <div className="loading-spinner-container">
    <div className="loading-spinner">
      Loading...
    </div>
    </div>
  );
};

export default LoadingSpinner;
