import React, { useEffect } from 'react';
import AuthComponent from './Auth';
import './App.css';
import './themes/coveo.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import ReactGA from 'react-ga4';
import ReactHeap from 'reactjs-heap';

const config = {
    gaTrackingId: process.env.REACT_APP_GA_TRACKING_CODE,
    heapId: process.env.REACT_APP_HEAP_TRACKING_ID,
    appTitle: process.env.REACT_APP_TITLE
};

ReactHeap.initialize(config.heapId)
ReactGA.initialize(config.gaTrackingId);
document.title = config.appTitle


const App: React.FC = () => {

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/" });
        // Function to get the value of a query parameter from the URL
        const getQueryParam = (name: string): string | null => {
            const urlParams = new URLSearchParams(window.location.search);
            return urlParams.get(name);
        };

        // Get the value of the 'q' query parameter
        const queryStringValue = getQueryParam('q');

        // Save the value in the browser's local storage
        if (queryStringValue) {
            localStorage.setItem('q', queryStringValue);
        }
        else {
            localStorage.removeItem('q');
        }
    }, []); // Empty dependency array ensures the effect runs only once

    return (
        <AuthComponent />
    );
};

export default App;